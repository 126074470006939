<style lang="less">
    @import '../styles/unlock.less';
</style>

<template>
    <transition name="show-unlock">
        <div v-if="showUnlock" class="unlock">
            <div class="unlock-bgc">
                <div class="unlock-icon"></div>
                <div class="tipMeaasge">已锁定，请输入教师密码解锁</div>
                <div class="loginForm">
                    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
                        <!-- <el-form-item prop="jobNo">
                            <el-input v-model="loginForm.jobNo" type="text" auto-complete="off" placeholder="请输入教师工号" ></el-input>
                        </el-form-item> -->
                        <el-form-item prop="password">
                            <el-input
                                v-model="loginForm.password"
                                type="password"
                                auto-complete="off"
                                placeholder="请输入密码"
                                @keyup.enter.native="handleLogin"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item style="width:100%;">
                        <el-button :loading="loading" class="login-btn" @click.native.prevent="handleLogin">
                            <span v-if="!loading">解锁</span>
                            <span v-else>解 锁 中...</span>
                        </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import Cookies from 'js-cookie';
    import { page } from './../../../../api/index.js';
    const sha512 = require('js-sha512');
    import { Message } from 'element-ui';

    export default {
        name: 'Unlock',
        props: {
            showUnlock: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                avatorLeft: '0px',
                inputLeft: '400px',
                password: '',
                check: null,
                loading: false,
                loginForm: {
                    jobNo: "",
                    password: "",
                    phoneNo: "",
                },
                loginRules: {
                    password: [
                    { required: true, trigger: "blur", message: "密码不能为空" },
                    ],
                },
            };
        },
        computed: {
            avatorPath() {
                return localStorage.avatorImgPath;
            }
        },
        methods: {
            handleLogin() {
                this.$refs.loginForm.validate((valid) => {
                    if (valid) {
                        let timestamp = new Date().getTime();
                        let pwd1 = sha512(`${this.loginForm.password}:yzyx`);
                        let pwd2 = sha512(`${pwd1}${timestamp}`);
                        const params = {
                            password: pwd2,
                            time: timestamp,
                            userId: localStorage.getItem('YZYX_CRM_userId'),
                        }
                        page.aflpUnLock(params, res => {
                            if(res.status === 1) {
                                Cookies.set('locking', '0');
                                console.log(Cookies.get('locking'))
                                this.$emit('on-unlock'); 
                            }else {
                                Message.error(res.message);
                            }
                        });
                    }
                });
            },
        }
    };
</script>
