<template>
    <div style="width: 100%;height: 100%;background: #2CBD74">
        <div class="unlock-con">
            <unlock 
                :show-unlock="showUnlock" 
                @on-unlock="handleUnlock"></unlock>
        </div>
    </div>
</template>

<script>
    import unlock from './unlock.vue';
    import Cookies from 'js-cookie';
    export default {
        components: {
            unlock
        },
        data() {
            return {
                showUnlock: false
            };
        },
        mounted() {
            this.showUnlock = true;
            if(!document.getElementById('lock_screen_back')) {
                let lockdiv = document.createElement('div');
                lockdiv.setAttribute('id', 'lock_screen_back');
                lockdiv.setAttribute('class', 'lock-screen-back');
                document.body.appendChild(lockdiv);
            }
            let lockScreenBack = document.getElementById('lock_screen_back');
            lockScreenBack.style.zIndex = -1;
        },
        methods: {
            handleUnlock() {
                let lockScreenBack = document.getElementById('lock_screen_back');
                this.showUnlock = false;
                lockScreenBack.style.zIndex = -1;
                lockScreenBack.style.boxShadow = '0 0 0 0 #2CBD74 inset';
                let obj = this.$route.query.paikeId ? { name: Cookies.get('last_page_name'), query: {paikeId: this.$route.query.paikeId} } : { name: Cookies.get('last_page_name') }
                this.$router.push(obj); // 解锁之后跳转到锁屏之前的页面
            }
        },
    };
</script>
